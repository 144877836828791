/* tslint:disable */
/* eslint-disable */
// @ts-nocheck
/**
 * Ampli - A strong typed wrapper for your Analytics
 *
 * This file is generated by Amplitude.
 * To update run 'ampli pull web'
 *
 * Required dependencies: @amplitude/analytics-browser@^1.3.0
 * Tracking Plan Version: 30
 * Build: 1.0.0
 * Runtime: browser:typescript-ampli-v2
 *
 * [View Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest)
 *
 * [Full Setup Instructions](https://data.amplitude.com/kadokado/KadoKado_Prod/implementation/web)
 */

import * as amplitude from '@amplitude/analytics-browser';

export type Environment = 'kadokado_prod';

export const ApiKey: Record<Environment, string> = {
  kadokado_prod: '50d1d44845e49a542d9bf6c882e41810'
};

/**
 * Default Amplitude configuration options. Contains tracking plan information.
 */
export const DefaultConfiguration: BrowserOptions = {
  plan: {
    version: '30',
    branch: 'main',
    source: 'web',
    versionId: '43126e7e-b91f-4613-848b-5fbf5bc3c14b'
  },
  ...{
    ingestionMetadata: {
      sourceName: 'browser-typescript-ampli',
      sourceVersion: '2.0.0'
    }
  }
};

export interface LoadOptionsBase { disabled?: boolean }

export type LoadOptionsWithEnvironment = LoadOptionsBase & { environment: Environment; client?: { configuration?: BrowserOptions; }; };
export type LoadOptionsWithApiKey = LoadOptionsBase & { client: { apiKey: string; configuration?: BrowserOptions; } };
export type LoadOptionsWithClientInstance = LoadOptionsBase & { client: { instance: BrowserClient; } };

export type LoadOptions = LoadOptionsWithEnvironment | LoadOptionsWithApiKey | LoadOptionsWithClientInstance;

export interface IdentifyProperties {
  /**
   * **用戶開啟的產品平台**
   *
   * * **網頁版(手機或桌機)：Web**
   *
   * * **app android：Android**
   *
   * * **app ios：iOS**
   */
  "device platform"?: string;
  "member email"?: string;
  "member kind"?: string;
  "member status"?: string;
}

export interface AmplitudeElementClickedProperties {
  /**
   * The aria-label of the element. Aria labels are intended for interactive elements without visible text in the DOM, and can be useful to further define your click events.
   *
   * For example, if the element clicked was:
   *
   * <button aria-label="Close" onclick="myDialog.close()"></button>
   *
   * The value would be: Close
   */
  "[Amplitude] Element Aria Label"?: any;
  /**
   * The class attribute of the HTML element.
   *
   * For example, if the element clicked was:
   *
   * <a id="myID">Home</a>
   *
   * The property value would be: myClass
   */
  "[Amplitude] Element Class"?: any;
  /**
   * Captures DOM elements and attributes of the element clicked and its parent and sibling elements.
   *
   * Used to power the visual labeling experience.
   */
  "[Amplitude] Element Hierarchy"?: any;
  /**
   * The href attribute, specifying the URL for a link. Only applies to <a> tags on the Element Clicked event.
   *
   * For example, if the element clicked was:
   *
   * <a href=”https://www.amplitude.com”>Home</a>
   *
   * The property value would be: https://www.amplitude.com
   */
  "[Amplitude] Element Href"?: any;
  /**
   * The id attribute of the HTML element.
   *
   * For example, if the element clicked was:
   *
   * <a id="myID">Home</a>
   *
   * The property value would be: myID
   */
  "[Amplitude] Element ID"?: any;
  /**
   * The text label in the parent element (or upper ancestors if not found in one level parent) of the element.
   *
   * For example, if the element clicked was:
   *
   * <div><span>Amplitude</span><a id="myID">Home</a></div>
   *
   * The value would be: Amplitude
   */
  "[Amplitude] Element Parent Label"?: any;
  /**
   * Captures the distance of the element from the left of the screen view in pixels.
   *
   * For example, a value of 600 means the element clicked was 600 pixels from the left of the screen view.
   */
  "[Amplitude] Element Position Left"?: any;
  /**
   * Captures the distance of the element from the top of the screen view in pixels.
   *
   * For example, a value of 400 means the element clicked was 400 pixels from the top of the screen view.
   */
  "[Amplitude] Element Position Top"?: any;
  /**
   * Property deprecated in favor of the Element Hierarchy property. Captures a unique CSS selector of the element.
   *
   * For example, if the element clicked was:
   *
   * <a id="myID">Home</a>
   *
   * The value could be: #myID
   */
  "[Amplitude] Element Selector"?: any;
  /**
   * The tag name of the HTML element.
   *
   * For example, if the element clicked was:
   *
   * <a href=”#”>Home</a>
   *
   * The property value would be: a
   */
  "[Amplitude] Element Tag"?: any;
  /**
   * The text content (innerText) of the HTML element. Only applies to the Element Clicked event.
   *
   * For example, if the element clicked was:
   *
   * <a href=”#”>Home</a>
   *
   * The property value would be: Home
   */
  "[Amplitude] Element Text"?: any;
  /**
   * Captures the page title of the page where the element was clicked.
   *
   * For example, if the page where the element was clicked has: <title>Amplitude</title>
   *
   * The value would be: Amplitude
   */
  "[Amplitude] Page Title"?: any;
  /**
   * Captures the URL of the page where the element was clicked.
   *
   * For example, a value of https://www.amplitude.com means that the element clicked was on this page.
   */
  "[Amplitude] Page URL"?: any;
  /**
   * Captures the height (in pixels) of the viewport when the element was clicked. This value is not related to the element itself.
   *
   * For example, a value of 900 means the viewport had a height of 900 pixels.
   */
  "[Amplitude] Viewport Height"?: any;
  /**
   * Captures the width (in pixels) of the viewport when the element was clicked. This value is not related to the element itself.
   *
   * For example, a value of 1200 means the viewport had a width of 1,200 pixels.
   */
  "[Amplitude] Viewport Width"?: any;
}

export interface ApplyWithdrawalReviewButtonClickedProperties {
  /**
   * **收益請款 - 申請請款審核（請款金額）**
   *
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "withdrawal amount": number;
}

export interface BookAllButtonClickedProperties {
  /**
   * 導覽列
   */
  "all button location": string;
}

export interface CallToActionClickedProperties {
  "call to action name": string;
  "call to action type": string;
}

export interface CategoryPageViewedProperties {
  /**
   * The name of the category
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 繼續閱讀, 最新連載, 最新上架, 完結作品, KadoKado小說主題競賽, 讀角券推薦作品, 熱門排行-日榜, 熱門排行-週榜, 熱門排行-月榜, 熱門排行-暢銷週榜, 熱門排行-收藏週榜, 熱門排行-R18週榜, 猜你喜歡, カクヨム (KAKUYOMU), 魔法 i 戀島, 簽約作品-華文原創, 簽約作品-網路獨家, 主題參賽作品, 熱門排行-暢銷日榜, 熱門排行-暢銷月榜, 熱門排行-收藏日榜, 熱門排行-收藏月榜, 熱門排行-R18日榜, 熱門排行-R18月榜, 推薦系統, 訂閱專區 |
   */
  "category name":
    | "繼續閱讀"
    | "最新連載"
    | "最新上架"
    | "完結作品"
    | "KadoKado小說主題競賽"
    | "讀角券推薦作品"
    | "熱門排行-日榜"
    | "熱門排行-週榜"
    | "熱門排行-月榜"
    | "熱門排行-暢銷週榜"
    | "熱門排行-收藏週榜"
    | "熱門排行-R18週榜"
    | "猜你喜歡"
    | "カクヨム (KAKUYOMU)"
    | "魔法 i 戀島"
    | "簽約作品-華文原創"
    | "簽約作品-網路獨家"
    | "主題參賽作品"
    | "熱門排行-暢銷日榜"
    | "熱門排行-暢銷月榜"
    | "熱門排行-收藏日榜"
    | "熱門排行-收藏月榜"
    | "熱門排行-R18日榜"
    | "熱門排行-R18月榜"
    | "推薦系統"
    | "訂閱專區";
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  };
}

export interface ChapterClappedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "clapped count": number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCommentPostedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCompletedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterCreatedProperties {
  /**
   * {title: '', points:5, rental:1, is_uniticket:True, listing_from: ''}
   */
  "chapter settings": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * **genres：\[奇幻, 穿越轉生\]**
   *
   * * **tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]**
   *
   * * **title display name：龍鳳莊物語**
   *
   * * **title id：17871**
   *
   * * **is contracted?：FALSE**
   *
   * * **is serialized?：TRUE**
   *
   * * **word count：5000**
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: {
      [k: string]: any;
    };
    "is contracted?"?: {
      [k: string]: any;
    };
    "is serialized?"?: {
      [k: string]: any;
    };
    tags?: any[];
    "title display name"?: {
      [k: string]: any;
    };
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterLockcedWallEncounteredProperties {
  "chapter id"?: string;
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   */
  "chapter unlock record": any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    titleId?: {
      [k: string]: any;
    };
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterLockedWallEncounteredProperties {
  "chapter id"?: string;
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  "chapter unlock record": {
    "chapter unlock cost"?: number;
    "chapter unlock period"?: "24h" | "48h" | "unlimited";
    "chapter unlock type"?: "points" | "ticket";
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterPublishedProperties {
  "chapter settings"?: any[];
  /**
   * [夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界]
   */
  tags: string;
}

export interface ChapterReportClickedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels?: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterReportedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels?: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterSavedProperties {
  "chapter id"?: string;
  "chapter settings"?: any[];
  "stopping time"?: any;
  /**
   * 1357
   */
  "word count": string;
}

export interface ChapterShareClickedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterStartedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface ChapterUnlockedProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  "chapter unlock record": {
    "chapter unlock cost"?: number;
    "chapter unlock period"?: "24h" | "48h" | "unlimited";
    "chapter unlock type"?: "points" | "ticket";
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
  "unlock method": string;
  "unlock period": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock points used"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock tickets used"?: number;
}

export interface CommentsButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 留言管理**
   *
   * * **側邊欄：sidebar - 留言管理**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 側邊攔 |
   */
  "Comments button location": "導覽列" | "側邊攔";
}

export interface DataCenterButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 數據中心**
   *
   * * **歷史閱覽數：總覽 - 歷史閱覽數**
   *
   * * **歷史收藏數：總覽 - 歷史收藏數**
   *
   * * **側邊欄：sidebar - 數據中心**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 歷史閱覽數, 歷史收藏數, 側邊欄 |
   */
  "data center button location": "導覽列" | "歷史閱覽數" | "歷史收藏數" | "側邊欄";
}

export interface ErrorEncounteredProperties {
  "error name": string;
  "error type": string;
}

export interface FloatingBtnClickedProperties {
  /**
   * 點擊的連結網址
   */
  "click url": string;
}

export interface GiftClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Enum Values | user page, book page, chapter page |
   */
  position: "user page" | "book page" | "chapter page";
}

export interface GiftNotificationButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 送禮通知**
   *
   * * **側邊欄：sidebar - 送禮通知**
   */
  "Gift button location": string;
}

export interface GiftSentProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | integer |
   */
  "gift price": number;
}

export interface LocalPushNotificationProperties {
  /**
   * **回訪推播的訊息**
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  "message info": {
    id?: string;
    name?: string;
  }[];
}

export interface LoggedInProperties {
  "login type": string;
}

export interface LoggedOutProperties {
  /**
   * **用戶登出的情境-**
   *
   * * **多 domain (例如當前中國版，假設連不到任何 domain，為了避免他畫面卡住也有設計會幫他登出)(僅APP有)：multiple domain**
   *
   * * **用戶主動點擊登出按鈕：by user**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | multiple domain, by user |
   */
  "logout type": "multiple domain" | "by user";
}

export interface LoginWallEncounteredProperties {
  /**
   * * **18+：進入18禁章節跳出警告  dialog**
   *
   * * **clap：點拍手後跳登入 dialog**
   *
   * * **favorite：點收藏後跳登入 dialog**
   *
   * * **comment：點留言後跳登入 dialog**
   *
   * * **purchase_chapter：點付費章節後跳登入 dialog**
   *
   * * **chapter_report：點章節檢舉後跳登入 dialog**
   *
   * * **gift：點送禮贊助後跳登入dialog**
   *
   * * **history：點我的小說頁後跳登入dialog**
   *
   * * **userpoints：點帳務中心後跳登入dialog**
   *
   * * **myreview：點年度回顧後跳登入dialog**
   *
   * * **announce_update：點我的通知>作品更新後跳登入dialog**
   *
   * * **announce_comment：點我的通知>留言通知後跳登入dialog**
   *
   * * **user：點個人設定頁後跳登入dialog**
   *
   * * **visitor_recommend_login_dialog：登入會員使用角點服務dialog(for ios)**
   *
   * * **visitor_buy_kpoint_dialog：未登入購買注意事項dialog(for ios)**
   *
   * * **visitor_account_center：點選「角角訪客」頭像後跳登入 dialog**
   *
   * * **visitor_favorite：點選收藏作品頁後跳登入 dialog**
   *
   * * **visitor_history：點選收藏->瀏覽紀錄頁後登入 dialog**
   *
   * * **visitor_comment：點選收藏->留言紀錄頁後登入 dialog**
   *
   * * **event_post：賽事討論區新增主題跳登入 dialog**
   *
   * * **event_reply：賽事討論區新增留言回應跳登入 dialog**
   *
   * * **ranking：排行榜頁點擊收藏後跳登入 dialog**
   *
   * * **visitor_subscription：訪客至訂閱管理頁面查看訂閱管理後跳登入dialog**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | kodopoints, clap, favorite, comment, purchase_chapter, chapter_report, gift, history, userpoints, myreview, announce_update, announce_comment, user, visitor_recommend_login_dialog, visitor_buy_kpoint_dialog, visitor_account_center, visitor_favorite, visitor_history, visitor_comment, event_post, event_reply, ranking, visitor_subscription, 18+ |
   */
  "account creation popped up":
    | "kodopoints"
    | "clap"
    | "favorite"
    | "comment"
    | "purchase_chapter"
    | "chapter_report"
    | "gift"
    | "history"
    | "userpoints"
    | "myreview"
    | "announce_update"
    | "announce_comment"
    | "user"
    | "visitor_recommend_login_dialog"
    | "visitor_buy_kpoint_dialog"
    | "visitor_account_center"
    | "visitor_favorite"
    | "visitor_history"
    | "visitor_comment"
    | "event_post"
    | "event_reply"
    | "ranking"
    | "visitor_subscription"
    | "18+";
}

export interface NovelFavoritedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    isContracted?: {
      [k: string]: any;
    };
    isSerialized?: {
      [k: string]: any;
    };
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * * **novel：在作品頁點收藏**
   *
   * * **chapter：在章節頁點收藏**
   *
   * * **category：在作品集合頁點收藏（排行榜、主題參賽作品頁、讀角券推薦作品、猜你喜歡頁）**
   *
   * * **search：在搜尋頁點收藏**
   *
   * * **home_guess_like：首頁猜你喜歡**
   *
   * * **onboarding_category：onboarding 分類推薦收藏**
   *
   * * **onboarding_platform：onboarding 平台推薦收藏**
   *
   * * **onboarding_bottomcard：onboarding 進入作品圖卡收藏**
   *
   * * **ticket_recommend：在讀角券推薦專區進行收藏**
   *
   * * **history：在收藏中的瀏覽紀錄點擊收藏**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | novel, chapter, category, search, home_guess_like, onboarding_category, onboarding_platform, onboarding_bottomcard, history, ticket_recommend |
   */
  "position type":
    | "novel"
    | "chapter"
    | "category"
    | "search"
    | "home_guess_like"
    | "onboarding_category"
    | "onboarding_platform"
    | "onboarding_bottomcard"
    | "history"
    | "ticket_recommend";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface NovelPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface NovelShareClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface OnboardingStepCompletedProperties {
  /**
   * selected_category_onboarding
   */
  "onboarding step": string;
}

export interface PointsAddedToCartProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface PointsClaimedProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface PointsPurchasedProperties {
  price?: any;
}

export interface PushNotificationClickedProperties {
  "message info"?: {
    id?: string;
    name?: string;
  };
  "message title"?: string;
}

export interface RankingCategoryClickedProperties {
  /**
   * **排行榜的計算邏輯：熱門、收藏、暢銷、18禁
   *  排行榜的分類：BL、戀愛言情、百合、奇幻...等
   *  排行榜的計算區間：日排行、周排行、月排行
   *  ranking category：{排行榜的計算邏輯}+{排行榜的分類}+{排行榜的計算區間}
   *  (例如，ranking category：熱門-BL-日排行)**
   */
  "ranking category": string;
}

export interface RatingClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    /**
     * 作品簽約與否?
     */
    "is contracted?"?: boolean;
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    tags?: any[];
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * 點選"評分"button的位置，目前分成top、bottom
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | top, bottom |
   */
  "rating position": "top" | "bottom";
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface RevenueReportButtonClickedProperties {
  /**
   * * **導覽列：Navbar - 收益請款**
   *
   * * **總覽：總覽 - 累積收益**
   *
   * * **側邊欄：sidebar - 收益請款**
   *
   * | Rule | Value |
   * |---|---|
   * | Enum Values | 導覽列, 總覽, 側邊欄 |
   */
  "revenue report button location": "導覽列" | "總覽" | "側邊欄";
}

export interface RevenueAmountProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $price?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  $productId?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $quantity?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  $revenue: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  $revenueType?: string;
}

export interface SearchCompletedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "search results count": number;
  "search term": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface SearchFilteredProperties {
  /**
   * **分級篩選**
   */
  "classification applied"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "filter applied"?: string[];
  /**
   * **\[BL, 奇幻\]**
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "genre filter applied"?: string[];
  /**
   * **進度篩選**
   */
  "progress applied"?: string;
  "sort applied"?: string;
  /**
   * **類型篩選**
   */
  "type applied"?: string;
  /**
   * **解鎖篩選**
   */
  "unlock applied"?: string;
  /**
   * **字數篩選**
   */
  "words applied"?: string;
}

export interface SearchResultClickedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    /**
     * 作品連載與否?
     */
    "is serialized?"?: boolean;
    "title display name"?: string;
    "title id"?: string;
    "word count"?: number;
  }[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface SubscriptionNovelClickedProperties {
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | object |
   */
  novels?: {
    genres?: any[];
    "title display name"?: string;
    "title id"?: string;
    titleId?: {
      [k: string]: any;
    };
  }[];
}

export interface TitlePublishedProperties {
  /**
   * 2024-06-04 12:34:00+08:00
   */
  "latest title published": string;
}

export interface UserPageViewedProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "user id": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Regex |  |
   */
  "user name"?: string;
}

export interface CallToActionProperties {
  "call to action name": string;
  "call to action type": string;
}

export interface ChapterDetailsProperties {
  /**
   * **章節名稱**
   */
  "chapter display name"?: string;
  "chapter id"?: string;
  "chapter locked?"?: boolean;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "chapter progress"?: number;
}

export interface ChapterUnlockProperties {
  /**
   * This is an array of objects and each object contains:
   *
   * * chapter unlock type: ticket, point
   *
   * * chapter unlock period: 24h, 48, unlimited
   *
   * * chapter unlock cost: 1,2...,n (points OR tickets)
   *
   * Sample code for paywall:
   *
   * ```
   * [
   *     {"chapter unlock type" : "ticket", "chapter unlock period" : "24h", "chapter unlock cost": 2},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10},
   *     {"chapter unlock type" : "points", "chapter unlock period" : "unlimited", "chapter unlock cost": 24}
   * ]
   * ```
   *
   * Sample code for unlocking:
   *
   * ```
   * [
   *     {"chapter unlock type" : "points", "chapter unlock period" : "48h", "chapter unlock cost": 10}
   * ]
   * ```
   */
  "chapter unlock record": any[];
  "unlock method": string;
  "unlock period": string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock points used"?: number;
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "unlock tickets used"?: number;
}

export interface ErrorProperties {
  "error name": string;
  "error type": string;
}

export interface NovelDetailsProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  genres?: string[];
  /**
   * * genres：\[奇幻, 穿越轉生\]
   *
   * * tags：\[夯特大大, 長篇, 色情, 小說, 巨乳, 成人, 當房東, 後宮, 女僕, 魔法少女, 穿越, 異世界, 拯救世界\]
   *
   * * title display name：龍鳳莊物語
   *
   * * title id：17871
   *
   * * is contracted?：FALSE
   *
   * * is serialized?：TRUE
   *
   * * word count：5000
   *
   * This uses 'Cart Analysis', see here: [Cart analysis: Use object arrays to drive behavioral insights – Amplitude](https://help.amplitude.com/hc/en-us/articles/9623000954907-Cart-analysis-Use-object-arrays-to-drive-behavioral-insights)
   */
  novels?: any[];
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  tags?: string[];
}

export interface PointsClaimingProperties {
  /**
   * Users can purchase multiple points packages.
   *
   * This is an array of 'points package' objects, each object contains:
   *
   * * package original price
   *
   * * package net price
   *
   * * package discount
   *
   * * package total points
   *
   * * package quantity
   *
   * * package name
   *
   *
   *
   */
  "points packages": any[];
}

export interface SearchProperties {
  /**
   * | Rule | Value |
   * |---|---|
   * | Type | number |
   */
  "search results count": number;
  "search term": string;
}

export interface SearchFilterProperties {
  /**
   * **分級篩選**
   */
  "classification applied"?: string;
  /**
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "filter applied"?: string[];
  /**
   * **\[BL, 奇幻\]**
   *
   * | Rule | Value |
   * |---|---|
   * | Item Type | string |
   */
  "genre filter applied"?: string[];
  /**
   * **進度篩選**
   */
  "progress applied"?: string;
  "sort applied"?: string;
  /**
   * **類型篩選**
   */
  "type applied"?: string;
  /**
   * **解鎖篩選**
   */
  "unlock applied"?: string;
  /**
   * **字數篩選**
   */
  "words applied"?: string;
}

export class Identify implements BaseEvent {
  event_type = amplitude.Types.SpecialEventType.IDENTIFY;

  constructor(
    public event_properties?: IdentifyProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AmplitudeElementClicked implements BaseEvent {
  event_type = '[Amplitude] Element Clicked';

  constructor(
    public event_properties?: AmplitudeElementClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class AccountCreated implements BaseEvent {
  event_type = 'Account Created';
}

export class AccountCreationStarted implements BaseEvent {
  event_type = 'Account Creation Started';
}

export class AccountVerified implements BaseEvent {
  event_type = 'Account Verified';
}

export class ApplyWithdrawalReviewButtonClicked implements BaseEvent {
  event_type = 'Apply Withdrawal Review Button Clicked';

  constructor(
    public event_properties: ApplyWithdrawalReviewButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class BookAllButtonClicked implements BaseEvent {
  event_type = 'Book All Button Clicked';

  constructor(
    public event_properties: BookAllButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CallToActionClicked implements BaseEvent {
  event_type = 'Call To Action Clicked';

  constructor(
    public event_properties: CallToActionClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class CancelSubscriptionClicked implements BaseEvent {
  event_type = 'Cancel Subscription Clicked';
}

export class CategoryPageViewed implements BaseEvent {
  event_type = 'Category Page Viewed';

  constructor(
    public event_properties: CategoryPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterClapped implements BaseEvent {
  event_type = 'Chapter Clapped';

  constructor(
    public event_properties: ChapterClappedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterCommentClicked implements BaseEvent {
  event_type = 'Chapter Comment Clicked';
}

export class ChapterCommentPosted implements BaseEvent {
  event_type = 'Chapter Comment Posted';

  constructor(
    public event_properties?: ChapterCommentPostedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterCompleted implements BaseEvent {
  event_type = 'Chapter Completed';

  constructor(
    public event_properties?: ChapterCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterContentsClicked implements BaseEvent {
  event_type = 'Chapter Contents Clicked';
}

export class ChapterCreated implements BaseEvent {
  event_type = 'Chapter Created';

  constructor(
    public event_properties: ChapterCreatedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterListsSubscriptionClicked implements BaseEvent {
  event_type = 'Chapter Lists Subscription Clicked';
}

export class ChapterLockcedWallEncountered implements BaseEvent {
  event_type = 'Chapter Lockced Wall Encountered';

  constructor(
    public event_properties: ChapterLockcedWallEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterLockedWallEncountered implements BaseEvent {
  event_type = 'Chapter Locked Wall Encountered';

  constructor(
    public event_properties: ChapterLockedWallEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterPublished implements BaseEvent {
  event_type = 'Chapter Published';

  constructor(
    public event_properties: ChapterPublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterReportClicked implements BaseEvent {
  event_type = 'Chapter Report Clicked';

  constructor(
    public event_properties?: ChapterReportClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterReported implements BaseEvent {
  event_type = 'Chapter Reported';

  constructor(
    public event_properties?: ChapterReportedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterSaved implements BaseEvent {
  event_type = 'Chapter Saved';

  constructor(
    public event_properties: ChapterSavedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterShareClicked implements BaseEvent {
  event_type = 'Chapter Share Clicked';

  constructor(
    public event_properties?: ChapterShareClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterStarted implements BaseEvent {
  event_type = 'Chapter Started';

  constructor(
    public event_properties?: ChapterStartedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterUnlocked implements BaseEvent {
  event_type = 'Chapter Unlocked';

  constructor(
    public event_properties: ChapterUnlockedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ChapterUnlockedForLoginDialogPageViewed implements BaseEvent {
  event_type = 'Chapter Unlocked For Login Dialog Page Viewed';
}

export class CommentsButtonClicked implements BaseEvent {
  event_type = 'Comments Button Clicked';

  constructor(
    public event_properties: CommentsButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ContestAllButtonClicked implements BaseEvent {
  event_type = 'Contest All Button Clicked';
}

export class DataCenterButtonClicked implements BaseEvent {
  event_type = 'Data Center Button Clicked';

  constructor(
    public event_properties: DataCenterButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class ErrorEncountered implements BaseEvent {
  event_type = 'Error Encountered';

  constructor(
    public event_properties: ErrorEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class FloatingBtnClicked implements BaseEvent {
  event_type = 'Floating Btn Clicked';

  constructor(
    public event_properties: FloatingBtnClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GetTicketCompleted implements BaseEvent {
  event_type = 'Get Ticket Completed';
}

export class GetTicketUncompleted implements BaseEvent {
  event_type = 'Get Ticket Uncompleted';
}

export class GetTicketUnompleted implements BaseEvent {
  event_type = 'Get Ticket Unompleted';
}

export class GiftClicked implements BaseEvent {
  event_type = 'Gift  Clicked';

  constructor(
    public event_properties: GiftClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GiftNotificationButtonClicked implements BaseEvent {
  event_type = 'Gift Notification Button Clicked';

  constructor(
    public event_properties: GiftNotificationButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class GiftPageViewed implements BaseEvent {
  event_type = 'Gift Page Viewed';
}

export class GiftSent implements BaseEvent {
  event_type = 'Gift Sent';

  constructor(
    public event_properties: GiftSentProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class HeaderSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Header Subscription Plan Clicked';
}

export class HomePageViewed implements BaseEvent {
  event_type = 'Home Page Viewed';
}

export class HomepageSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Homepage Subscription Plan Clicked';
}

export class LocalPushNotification implements BaseEvent {
  event_type = 'Local Push Notification';

  constructor(
    public event_properties: LocalPushNotificationProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedIn implements BaseEvent {
  event_type = 'Logged In';

  constructor(
    public event_properties: LoggedInProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoggedOut implements BaseEvent {
  event_type = 'Logged Out';

  constructor(
    public event_properties: LoggedOutProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class LoginWallEncountered implements BaseEvent {
  event_type = 'Login Wall Encountered';

  constructor(
    public event_properties: LoginWallEncounteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class MemberPageViewed implements BaseEvent {
  event_type = 'Member Page Viewed';
}

export class MyAccountCenterPageViewed implements BaseEvent {
  event_type = 'My Account Center Page Viewed';
}

export class NovelFavorited implements BaseEvent {
  event_type = 'Novel Favorited';

  constructor(
    public event_properties: NovelFavoritedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NovelPageViewed implements BaseEvent {
  event_type = 'Novel Page Viewed';

  constructor(
    public event_properties?: NovelPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class NovelShareClicked implements BaseEvent {
  event_type = 'Novel Share Clicked';

  constructor(
    public event_properties?: NovelShareClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OnboardingNovelLikeClicked implements BaseEvent {
  event_type = 'Onboarding Novel Like Clicked';
}

export class OnboardingStarted implements BaseEvent {
  event_type = 'Onboarding Started';
}

export class OnboardingStepCompleted implements BaseEvent {
  event_type = 'Onboarding Step Completed';

  constructor(
    public event_properties: OnboardingStepCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class OpenAppToSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Open App to Subscription Plan Clicked';
}

export class PointsAddedToCart implements BaseEvent {
  event_type = 'Points Added To Cart';

  constructor(
    public event_properties: PointsAddedToCartProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PointsClaimed implements BaseEvent {
  event_type = 'Points Claimed';

  constructor(
    public event_properties: PointsClaimedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PointsPurchased implements BaseEvent {
  event_type = 'Points Purchased';

  constructor(
    public event_properties?: PointsPurchasedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class PointsStorePageViewed implements BaseEvent {
  event_type = 'Points Store Page Viewed';
}

export class PostClicked implements BaseEvent {
  event_type = 'Post Clicked';
}

export class PromoDialogClicked implements BaseEvent {
  event_type = 'Promo Dialog Clicked';
}

export class PurchaseGiftPointsClicked implements BaseEvent {
  event_type = 'Purchase Gift Points Clicked';
}

export class PurchaseSubscriptionPlanClicked implements BaseEvent {
  event_type = 'Purchase Subscription Plan Clicked';
}

export class PushNotificationClicked implements BaseEvent {
  event_type = 'Push Notification Clicked';

  constructor(
    public event_properties?: PushNotificationClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RankingCategoryClicked implements BaseEvent {
  event_type = 'Ranking Category Clicked';

  constructor(
    public event_properties: RankingCategoryClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RankingFilterClicked implements BaseEvent {
  event_type = 'Ranking Filter Clicked';
}

export class RatingClicked implements BaseEvent {
  event_type = 'Rating Clicked';

  constructor(
    public event_properties: RatingClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RecommendationRefreshed implements BaseEvent {
  event_type = 'Recommendation Refreshed';
}

export class RecommendedCategoriesPageViewed implements BaseEvent {
  event_type = 'Recommended Categories Page Viewed';
}

export class RecommendedNovelsPageViewed implements BaseEvent {
  event_type = 'Recommended Novels Page Viewed';
}

export class RecommenderSystemClicked implements BaseEvent {
  event_type = 'Recommender System Clicked';
}

export class RenewSubscriptionClicked implements BaseEvent {
  event_type = 'Renew Subscription  Clicked';
}

export class ReplyClicked implements BaseEvent {
  event_type = 'Reply Clicked';
}

export class RevenueReportButtonClicked implements BaseEvent {
  event_type = 'Revenue Report Button Clicked';

  constructor(
    public event_properties: RevenueReportButtonClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RevenueAmount implements BaseEvent {
  event_type = 'revenue_amount';

  constructor(
    public event_properties: RevenueAmountProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class RewardsPageViewed implements BaseEvent {
  event_type = 'Rewards Page Viewed';
}

export class SearchCompleted implements BaseEvent {
  event_type = 'Search Completed';

  constructor(
    public event_properties: SearchCompletedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchFiltered implements BaseEvent {
  event_type = 'Search Filtered';

  constructor(
    public event_properties?: SearchFilteredProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SearchPageViewed implements BaseEvent {
  event_type = 'Search Page Viewed';
}

export class SearchResultClicked implements BaseEvent {
  event_type = 'Search Result Clicked';

  constructor(
    public event_properties?: SearchResultClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SignupAccountPageViewed implements BaseEvent {
  event_type = 'Signup Account Page Viewed';
}

export class SignupChoicePageViewed implements BaseEvent {
  event_type = 'Signup Choice Page Viewed';
}

export class SignupClicked implements BaseEvent {
  event_type = 'Signup Clicked';
}

export class SignupEmailClicked implements BaseEvent {
  event_type = 'Signup Email Clicked';
}

export class SignupFinishedPageViewed implements BaseEvent {
  event_type = 'Signup Finished Page Viewed';
}

export class SignupFnishedPageViewed implements BaseEvent {
  event_type = 'Signup Fnished Page Viewed';
}

export class SignupGoogleClicked implements BaseEvent {
  event_type = 'Signup Google Clicked';
}

export class SignupPasswordPageViewed implements BaseEvent {
  event_type = 'Signup Password Page Viewed';
}

export class SmartbannerClicked implements BaseEvent {
  event_type = 'Smartbanner Clicked';
}

export class SubscriptionDialogViewed implements BaseEvent {
  event_type = 'Subscription Dialog Viewed';
}

export class SubscriptionFaqClicked implements BaseEvent {
  event_type = 'Subscription FAQ Clicked';
}

export class SubscriptionNovelClicked implements BaseEvent {
  event_type = 'Subscription Novel Clicked';

  constructor(
    public event_properties?: SubscriptionNovelClickedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class SubscriptionPlanViewed implements BaseEvent {
  event_type = 'Subscription Plan Viewed';
}

export class SubscriptionStatePlanClicked implements BaseEvent {
  event_type = 'Subscription State Plan Clicked';
}

export class SubscriptionStateViewed implements BaseEvent {
  event_type = 'Subscription State Viewed';
}

export class SubscriptionSuccessViewed implements BaseEvent {
  event_type = 'Subscription Success Viewed';
}

export class TitlePublished implements BaseEvent {
  event_type = 'Title Published';

  constructor(
    public event_properties: TitlePublishedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class UserPageShareClicked implements BaseEvent {
  event_type = 'User Page Share Clicked';
}

export class UserPageViewed implements BaseEvent {
  event_type = 'User Page Viewed';

  constructor(
    public event_properties: UserPageViewedProperties,
  ) {
    this.event_properties = event_properties;
  }
}

export class VerifiedBadgeButtonClicked implements BaseEvent {
  event_type = 'Verified Badge Button Clicked';
}

export class VisitorPurchasePointsClicked implements BaseEvent {
  event_type = 'Visitor Purchase Points Clicked';
}

export class VistorToHomepageClicked implements BaseEvent {
  event_type = 'Vistor To Homepage Clicked';
}

export class WithdrawalRecordButtonClicked implements BaseEvent {
  event_type = 'Withdrawal Record Button Clicked';
}

export type PromiseResult<T> = { promise: Promise<T | void> };

const getVoidPromiseResult = () => ({ promise: Promise.resolve() });

// prettier-ignore
export class Ampli {
  private disabled: boolean = false;
  private amplitude?: BrowserClient;

  get client(): BrowserClient {
    this.isInitializedAndEnabled();
    return this.amplitude!;
  }

  get isLoaded(): boolean {
    return this.amplitude != null;
  }

  private isInitializedAndEnabled(): boolean {
    if (!this.amplitude) {
      console.error('ERROR: Ampli is not yet initialized. Have you called ampli.load() on app start?');
      return false;
    }
    return !this.disabled;
  }

  /**
   * Initialize the Ampli SDK. Call once when your application starts.
   *
   * @param options Configuration options to initialize the Ampli SDK with.
   */
  load(options: LoadOptions): PromiseResult<void> {
    this.disabled = options.disabled ?? false;

    if (this.amplitude) {
      console.warn('WARNING: Ampli is already intialized. Ampli.load() should be called once at application startup.');
      return getVoidPromiseResult();
    }

    let apiKey: string | null = null;
    if (options.client && 'apiKey' in options.client) {
      apiKey = options.client.apiKey;
    } else if ('environment' in options) {
      apiKey = ApiKey[options.environment];
    }

    if (options.client && 'instance' in options.client) {
      this.amplitude = options.client.instance;
    } else if (apiKey) {
      this.amplitude = amplitude.createInstance();
      const configuration = (options.client && 'configuration' in options.client) ? options.client.configuration : {};
      return this.amplitude.init(apiKey, undefined, { ...DefaultConfiguration, ...configuration });
    } else {
      console.error("ERROR: ampli.load() requires 'environment', 'client.apiKey', or 'client.instance'");
    }

    return getVoidPromiseResult();
  }

  /**
   * Identify a user and set user properties.
   *
   * @param userId The user's id.
   * @param properties The user properties.
   * @param options Optional event options.
   */
  identify(
    userId: string | undefined,
    properties?: IdentifyProperties,
    options?: EventOptions,
  ): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    if (userId) {
      options = {...options,  user_id: userId};
    }

    const amplitudeIdentify = new amplitude.Identify();
    const eventProperties = properties;
    if (eventProperties != null) {
      for (const [key, value] of Object.entries(eventProperties)) {
        amplitudeIdentify.set(key, value);
      }
    }
    return this.amplitude!.identify(
      amplitudeIdentify,
      options,
    );
  }

 /**
  * Flush the event.
  */
  flush() : PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.flush();
  }

  /**
   * Track event
   *
   * @param event The event to track.
   * @param options Optional event options.
   */
  track(event: Event, options?: EventOptions): PromiseResult<Result> {
    if (!this.isInitializedAndEnabled()) {
      return getVoidPromiseResult();
    }

    return this.amplitude!.track(event, undefined, options);
  }

  /**
   * [Amplitude] Element Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/%5BAmplitude%5D%20Element%20Clicked)
   *
   * Captures clicks on page elements.
   *
   *
   * The default configuration is designed to capture user interactions with interactable elements on your page. This optimizes for capturing important interactions with your app while eliminating event noise (clicks to highlight text, white-space clicks, etc.). This includes:
   *
   * * All clicks on form elements: a, button, input, select, textarea, label, and elements where contenteditable is set to true.
   *
   * * All clicks on video and audio elements
   *
   * * Clicks on select elements that result in a change on your page (i.e. a modal appearing) or navigation to another page. These elements include divs, spans, and headers.
   *
   * * All clicks on elements with an attribute of “data-amp-default-track” or a class of “amp-default-track”.
   *
   * You can customize this configuration to add or remove selectors, and can choose if you want those selectors to always be tracked, or only track when the click results in a change to the page.
   *
   * @param properties The event's properties (e.g. [Amplitude] Element Aria Label)
   * @param options Amplitude event options.
   */
  amplitudeElementClicked(
    properties?: AmplitudeElementClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new AmplitudeElementClicked(properties), options);
  }

  /**
   * Account Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Created)
   *
   * This event tracks when a user creates a new account on our platform
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountCreated(
    options?: EventOptions,
  ) {
    return this.track(new AccountCreated(), options);
  }

  /**
   * Account Creation Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Creation%20Started)
   *
   * This event tracks the initiation of the account creation process for user accounts
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountCreationStarted(
    options?: EventOptions,
  ) {
    return this.track(new AccountCreationStarted(), options);
  }

  /**
   * Account Verified
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Account%20Verified)
   *
   * This event is triggered when a user's account has been successfully verified
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  accountVerified(
    options?: EventOptions,
  ) {
    return this.track(new AccountVerified(), options);
  }

  /**
   * Apply Withdrawal Review Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Apply%20Withdrawal%20Review%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款 - 申請請款審核**
   *
   * @param properties The event's properties (e.g. withdrawal amount)
   * @param options Amplitude event options.
   */
  applyWithdrawalReviewButtonClicked(
    properties: ApplyWithdrawalReviewButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ApplyWithdrawalReviewButtonClicked(properties), options);
  }

  /**
   * Book All Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Book%20All%20Button%20Clicked)
   *
   * 使用者點擊創作者中心的按鈕元件 - 我的作品／擁有章節數
   *
   * @param properties The event's properties (e.g. all button location)
   * @param options Amplitude event options.
   */
  bookAllButtonClicked(
    properties: BookAllButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new BookAllButtonClicked(properties), options);
  }

  /**
   * Call To Action Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Call%20To%20Action%20Clicked)
   *
   * TO BE SKIPPED
   *
   *
   * This event tracks when a user clicks on a call to action button within the navigation of our platform
   *
   *
   *
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. call to action name)
   * @param options Amplitude event options.
   */
  callToActionClicked(
    properties: CallToActionClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CallToActionClicked(properties), options);
  }

  /**
   * Cancel Subscription Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Cancel%20Subscription%20Clicked)
   *
   * **點擊「仍要取消」Button**
   *
   * @param options Amplitude event options.
   */
  cancelSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new CancelSubscriptionClicked(), options);
  }

  /**
   * Category Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Category%20Page%20Viewed)
   *
   * This event tracks when a user views a category page while navigating through the website
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. category name)
   * @param options Amplitude event options.
   */
  categoryPageViewed(
    properties: CategoryPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CategoryPageViewed(properties), options);
  }

  /**
   * Chapter Clapped
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Clapped)
   *
   * This event tracks when a user claps for a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterClapped(
    properties: ChapterClappedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterClapped(properties), options);
  }

  /**
   * Chapter Comment Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Comment%20Clicked)
   *
   * 點擊進入章節留言頁
   *
   * @param options Amplitude event options.
   */
  chapterCommentClicked(
    options?: EventOptions,
  ) {
    return this.track(new ChapterCommentClicked(), options);
  }

  /**
   * Chapter Comment Posted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Comment%20Posted)
   *
   * This event is triggered when a user posts a comment on a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterCommentPosted(
    properties?: ChapterCommentPostedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCommentPosted(properties), options);
  }

  /**
   * Chapter Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Completed)
   *
   * This event is triggered when a user completes a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterCompleted(
    properties?: ChapterCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCompleted(properties), options);
  }

  /**
   * Chapter Contents Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Contents%20Clicked)
   *
   * 點目錄 icon 跳出 bottom sheet
   *
   * @param options Amplitude event options.
   */
  chapterContentsClicked(
    options?: EventOptions,
  ) {
    return this.track(new ChapterContentsClicked(), options);
  }

  /**
   * Chapter Created
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Created)
   *
   * **每次建立新章節**
   *
   * @param properties The event's properties (e.g. chapter settings)
   * @param options Amplitude event options.
   */
  chapterCreated(
    properties: ChapterCreatedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterCreated(properties), options);
  }

  /**
   * Chapter Lists Subscription Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Lists%20Subscription%20Clicked)
   *
   * **當使用者點擊作品頁中的訂閱章節列表**
   *
   * @param options Amplitude event options.
   */
  chapterListsSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new ChapterListsSubscriptionClicked(), options);
  }

  /**
   * Chapter Lockced Wall Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Lockced%20Wall%20Encountered)
   *
   * When a user is blocked because they have not purchase the novel to continue reading
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterLockcedWallEncountered(
    properties: ChapterLockcedWallEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterLockcedWallEncountered(properties), options);
  }

  /**
   * Chapter Locked Wall Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Locked%20Wall%20Encountered)
   *
   * This event tracks when a user encounters a locked wall while reading a chapter in the app
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterLockedWallEncountered(
    properties: ChapterLockedWallEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterLockedWallEncountered(properties), options);
  }

  /**
   * Chapter Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Published)
   *
   * **章節上架**
   *
   * @param properties The event's properties (e.g. chapter settings)
   * @param options Amplitude event options.
   */
  chapterPublished(
    properties: ChapterPublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterPublished(properties), options);
  }

  /**
   * Chapter Report Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Report%20Clicked)
   *
   * This event is triggered when a user reports a chapter while reading
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterReportClicked(
    properties?: ChapterReportClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterReportClicked(properties), options);
  }

  /**
   * Chapter Reported
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Reported)
   *
   * This event is triggered when a user reports a chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterReported(
    properties?: ChapterReportedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterReported(properties), options);
  }

  /**
   * Chapter Saved
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Saved)
   *
   * 每次儲存文章（包含 autosave）
   *
   * @param properties The event's properties (e.g. chapter id)
   * @param options Amplitude event options.
   */
  chapterSaved(
    properties: ChapterSavedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterSaved(properties), options);
  }

  /**
   * Chapter Share Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Share%20Clicked)
   *
   * This event tracks when a user clicks on the "Share" button while reading a chapter
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterShareClicked(
    properties?: ChapterShareClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterShareClicked(properties), options);
  }

  /**
   * Chapter Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Started)
   *
   * This event is triggered when a user starts reading a new chapter in a book or any other content. It helps track user engagement and progress within the content
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterStarted(
    properties?: ChapterStartedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterStarted(properties), options);
  }

  /**
   * Chapter Unlocked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Unlocked)
   *
   * This event tracks when a user unlocks a new chapter while reading
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. chapter display name)
   * @param options Amplitude event options.
   */
  chapterUnlocked(
    properties: ChapterUnlockedProperties,
    options?: EventOptions,
  ) {
    return this.track(new ChapterUnlocked(properties), options);
  }

  /**
   * Chapter Unlocked For Login Dialog Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Chapter%20Unlocked%20For%20Login%20Dialog%20Page%20Viewed)
   *
   * 章節付費牆後跳出登入引導Dialog
   *
   * @param options Amplitude event options.
   */
  chapterUnlockedForLoginDialogPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new ChapterUnlockedForLoginDialogPageViewed(), options);
  }

  /**
   * Comments Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Comments%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 留言管理**
   *
   * @param properties The event's properties (e.g. Comments button location)
   * @param options Amplitude event options.
   */
  commentsButtonClicked(
    properties: CommentsButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new CommentsButtonClicked(properties), options);
  }

  /**
   * Contest All Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Contest%20All%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 徵文比賽投稿**
   *
   * @param options Amplitude event options.
   */
  contestAllButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new ContestAllButtonClicked(), options);
  }

  /**
   * Data Center Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Data%20Center%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 數據中心**
   *
   * @param properties The event's properties (e.g. data center button location)
   * @param options Amplitude event options.
   */
  dataCenterButtonClicked(
    properties: DataCenterButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new DataCenterButtonClicked(properties), options);
  }

  /**
   * Error Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Error%20Encountered)
   *
   * This event tracks when an error is encountered during a user's interaction with our platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. error name)
   * @param options Amplitude event options.
   */
  errorEncountered(
    properties: ErrorEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new ErrorEncountered(properties), options);
  }

  /**
   * Floating Btn Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Floating%20Btn%20Clicked)
   *
   * **點擊右下角的Floating Button**
   *
   * @param properties The event's properties (e.g. click url)
   * @param options Amplitude event options.
   */
  floatingBtnClicked(
    properties: FloatingBtnClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new FloatingBtnClicked(properties), options);
  }

  /**
   * Get Ticket Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Get%20Ticket%20Completed)
   *
   * 取得獎勵Dialog(已註冊)
   *
   * @param options Amplitude event options.
   */
  getTicketCompleted(
    options?: EventOptions,
  ) {
    return this.track(new GetTicketCompleted(), options);
  }

  /**
   * Get Ticket Uncompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Get%20Ticket%20Uncompleted)
   *
   * **取得獎勵Dialog(未註冊)**
   *
   * @param options Amplitude event options.
   */
  getTicketUncompleted(
    options?: EventOptions,
  ) {
    return this.track(new GetTicketUncompleted(), options);
  }

  /**
   * Get Ticket Unompleted
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Get%20Ticket%20Unompleted)
   *
   * 取得獎勵Dialog(未註冊)
   *
   * @param options Amplitude event options.
   */
  getTicketUnompleted(
    options?: EventOptions,
  ) {
    return this.track(new GetTicketUnompleted(), options);
  }

  /**
   * Gift  Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20%20Clicked)
   *
   * **當用戶點擊送禮跳出選購禮物視窗**
   *
   * @param properties The event's properties (e.g. position)
   * @param options Amplitude event options.
   */
  giftClicked(
    properties: GiftClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GiftClicked(properties), options);
  }

  /**
   * Gift Notification Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20Notification%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 送禮通知**
   *
   * @param properties The event's properties (e.g. Gift button location)
   * @param options Amplitude event options.
   */
  giftNotificationButtonClicked(
    properties: GiftNotificationButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new GiftNotificationButtonClicked(properties), options);
  }

  /**
   * Gift Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20Page%20Viewed)
   *
   * **當用戶瀏覽送禮頁面時**
   *
   * @param options Amplitude event options.
   */
  giftPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new GiftPageViewed(), options);
  }

  /**
   * Gift Sent
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Gift%20Sent)
   *
   * **在選購禮物視窗中點擊立即送禮(送禮成功)**
   *
   * @param properties The event's properties (e.g. gift price)
   * @param options Amplitude event options.
   */
  giftSent(
    properties: GiftSentProperties,
    options?: EventOptions,
  ) {
    return this.track(new GiftSent(properties), options);
  }

  /**
   * Header Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Header%20Subscription%20Plan%20Clicked)
   *
   * **首頁Header點選"訂閱專區"**
   *
   * @param options Amplitude event options.
   */
  headerSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new HeaderSubscriptionPlanClicked(), options);
  }

  /**
   * Home Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Home%20Page%20Viewed)
   *
   * This event tracks when a user views the home page of our website. It provides insights into user navigation behavior and can be used to analyze engagement and user flow on the home page
   *
   * Owner: James Lindsay
   *
   * @param options Amplitude event options.
   */
  homePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new HomePageViewed(), options);
  }

  /**
   * Homepage Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Homepage%20Subscription%20Plan%20Clicked)
   *
   * **首頁作品專區點"更多"**
   *
   * @param options Amplitude event options.
   */
  homepageSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new HomepageSubscriptionPlanClicked(), options);
  }

  /**
   * Local Push Notification
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Local%20Push%20Notification)
   *
   * **排程訪客定時推播時**
   *
   * @param properties The event's properties (e.g. message info)
   * @param options Amplitude event options.
   */
  localPushNotification(
    properties: LocalPushNotificationProperties,
    options?: EventOptions,
  ) {
    return this.track(new LocalPushNotification(properties), options);
  }

  /**
   * Logged In
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Logged%20In)
   *
   * This event tracks when a user successfully logs into their account.
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. login type)
   * @param options Amplitude event options.
   */
  loggedIn(
    properties: LoggedInProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedIn(properties), options);
  }

  /**
   * Logged Out
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Logged%20Out)
   *
   * This event tracks when a user logs out of their account
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. logout type)
   * @param options Amplitude event options.
   */
  loggedOut(
    properties: LoggedOutProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoggedOut(properties), options);
  }

  /**
   * Login Wall Encountered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Login%20Wall%20Encountered)
   *
   * This event tracks when a user encounters a login wall while trying to access content within the platform that requires an account but they aren't currently logged in. 
   *
   *
   * This includes:
   *
   * * Clicking on a locked chaper
   *
   * * Clicking on comment button
   *
   * * Clicking on like button
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. account creation popped up)
   * @param options Amplitude event options.
   */
  loginWallEncountered(
    properties: LoginWallEncounteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new LoginWallEncountered(properties), options);
  }

  /**
   * Member Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Member%20Page%20Viewed)
   *
   * 當用戶進入瀏覽會員頁時
   *
   * @param options Amplitude event options.
   */
  memberPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new MemberPageViewed(), options);
  }

  /**
   * My Account Center Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/My%20Account%20Center%20Page%20Viewed)
   *
   * **當用戶瀏覽我的帳戶中心頁時**
   *
   * @param options Amplitude event options.
   */
  myAccountCenterPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new MyAccountCenterPageViewed(), options);
  }

  /**
   * Novel Favorited
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Favorited)
   *
   * This event tracks when a user favorites a novel on the platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelFavorited(
    properties: NovelFavoritedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelFavorited(properties), options);
  }

  /**
   * Novel Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Page%20Viewed)
   *
   * This event tracks when a user views a page related to novels on our platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelPageViewed(
    properties?: NovelPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelPageViewed(properties), options);
  }

  /**
   * Novel Share Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Novel%20Share%20Clicked)
   *
   * This event tracks when a user clicks on the "Share" button for a specific novel, indicating their intention to share it with others
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  novelShareClicked(
    properties?: NovelShareClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new NovelShareClicked(properties), options);
  }

  /**
   * Onboarding Novel Like Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Onboarding%20Novel%20Like%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  onboardingNovelLikeClicked(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingNovelLikeClicked(), options);
  }

  /**
   * Onboarding Started
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Onboarding%20Started)
   *
   * **When the user starts an onboarding step**
   *
   * @param options Amplitude event options.
   */
  onboardingStarted(
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStarted(), options);
  }

  /**
   * Onboarding Step Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Onboarding%20Step%20Completed)
   *
   * This event tracks the completion of a specific step in the onboarding process. It provides insights into user progress and engagement during the onboarding experience
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. onboarding step)
   * @param options Amplitude event options.
   */
  onboardingStepCompleted(
    properties: OnboardingStepCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new OnboardingStepCompleted(properties), options);
  }

  /**
   * Open App to Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Open%20App%20to%20Subscription%20Plan%20Clicked)
   *
   * **點擊「開啟 App 前往訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  openAppToSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new OpenAppToSubscriptionPlanClicked(), options);
  }

  /**
   * Points Added To Cart
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Added%20To%20Cart)
   *
   * This event tracks the number of points that are added to the cart during a points purchase transaction
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. points packages)
   * @param options Amplitude event options.
   */
  pointsAddedToCart(
    properties: PointsAddedToCartProperties,
    options?: EventOptions,
  ) {
    return this.track(new PointsAddedToCart(properties), options);
  }

  /**
   * Points Claimed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Claimed)
   *
   * This event tracks when users submits their points serial number to claim a purchased points package
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. points packages)
   * @param options Amplitude event options.
   */
  pointsClaimed(
    properties: PointsClaimedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PointsClaimed(properties), options);
  }

  /**
   * Points Purchased
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Purchased)
   *
   * This event tracks when users purchase points on the KadoKado platform
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. price)
   * @param options Amplitude event options.
   */
  pointsPurchased(
    properties?: PointsPurchasedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PointsPurchased(properties), options);
  }

  /**
   * Points Store Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Points%20Store%20Page%20Viewed)
   *
   * 瀏覽角點商店
   *
   * @param options Amplitude event options.
   */
  pointsStorePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new PointsStorePageViewed(), options);
  }

  /**
   * Post Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Post%20Clicked)
   *
   * **當用戶在賽事頁送出「新增主題」**
   *
   * @param options Amplitude event options.
   */
  postClicked(
    options?: EventOptions,
  ) {
    return this.track(new PostClicked(), options);
  }

  /**
   * Promo Dialog Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Promo%20Dialog%20Clicked)
   *
   * **用戶點擊訂閱提示dialog上的「查看訂閱作品」**
   *
   * @param options Amplitude event options.
   */
  promoDialogClicked(
    options?: EventOptions,
  ) {
    return this.track(new PromoDialogClicked(), options);
  }

  /**
   * Purchase Gift Points Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Purchase%20Gift%20Points%20Clicked)
   *
   * **當用戶點擊"$購買角點"button**
   *
   * @param options Amplitude event options.
   */
  purchaseGiftPointsClicked(
    options?: EventOptions,
  ) {
    return this.track(new PurchaseGiftPointsClicked(), options);
  }

  /**
   * Purchase Subscription Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Purchase%20Subscription%20Plan%20Clicked)
   *
   * **點擊「立即訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  purchaseSubscriptionPlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new PurchaseSubscriptionPlanClicked(), options);
  }

  /**
   * Push Notification Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Push%20Notification%20Clicked)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. message info)
   * @param options Amplitude event options.
   */
  pushNotificationClicked(
    properties?: PushNotificationClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new PushNotificationClicked(properties), options);
  }

  /**
   * Ranking Category Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Ranking%20Category%20Clicked)
   *
   * **點選各類別的排行榜**
   *
   * @param properties The event's properties (e.g. ranking category)
   * @param options Amplitude event options.
   */
  rankingCategoryClicked(
    properties: RankingCategoryClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RankingCategoryClicked(properties), options);
  }

  /**
   * Ranking Filter Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Ranking%20Filter%20Clicked)
   *
   * **點選排行榜篩選器**
   *
   * @param options Amplitude event options.
   */
  rankingFilterClicked(
    options?: EventOptions,
  ) {
    return this.track(new RankingFilterClicked(), options);
  }

  /**
   * Rating Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Rating%20Clicked)
   *
   * 用戶針對作品點選"評分"button(包含點選"修改評分"button)
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  ratingClicked(
    properties: RatingClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RatingClicked(properties), options);
  }

  /**
   * Recommendation Refreshed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Recommendation%20Refreshed)
   *
   * **點選"推薦系統"中的"換一換"功能**
   *
   * @param options Amplitude event options.
   */
  recommendationRefreshed(
    options?: EventOptions,
  ) {
    return this.track(new RecommendationRefreshed(), options);
  }

  /**
   * Recommended Categories Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Recommended%20Categories%20Page%20Viewed)
   *
   * 瀏覽推薦分類頁
   *
   * @param options Amplitude event options.
   */
  recommendedCategoriesPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new RecommendedCategoriesPageViewed(), options);
  }

  /**
   * Recommended Novels Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Recommended%20Novels%20Page%20Viewed)
   *
   * 瀏覽推薦作品頁
   *
   * @param options Amplitude event options.
   */
  recommendedNovelsPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new RecommendedNovelsPageViewed(), options);
  }

  /**
   * Recommender System Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Recommender%20System%20Clicked)
   *
   * **當用戶直接點選搜尋首頁的探索更多區塊(系統推薦區塊)**
   *
   * @param options Amplitude event options.
   */
  recommenderSystemClicked(
    options?: EventOptions,
  ) {
    return this.track(new RecommenderSystemClicked(), options);
  }

  /**
   * Renew Subscription  Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Renew%20Subscription%20%20Clicked)
   *
   * **點擊「保持訂閱」Button**
   *
   * @param options Amplitude event options.
   */
  renewSubscriptionClicked(
    options?: EventOptions,
  ) {
    return this.track(new RenewSubscriptionClicked(), options);
  }

  /**
   * Reply Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Reply%20Clicked)
   *
   * **當用戶在賽事頁送出「新增回應」**
   *
   * @param options Amplitude event options.
   */
  replyClicked(
    options?: EventOptions,
  ) {
    return this.track(new ReplyClicked(), options);
  }

  /**
   * Revenue Report Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Revenue%20Report%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款／累積收益**
   *
   * @param properties The event's properties (e.g. revenue report button location)
   * @param options Amplitude event options.
   */
  revenueReportButtonClicked(
    properties: RevenueReportButtonClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueReportButtonClicked(properties), options);
  }

  /**
   * revenue_amount
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/revenue_amount)
   *
   * Event has no description in tracking plan.
   *
   * @param properties The event's properties (e.g. $price)
   * @param options Amplitude event options.
   */
  revenueAmount(
    properties: RevenueAmountProperties,
    options?: EventOptions,
  ) {
    return this.track(new RevenueAmount(properties), options);
  }

  /**
   * Rewards Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Rewards%20Page%20Viewed)
   *
   * 瀏覽限定獎勵頁
   *
   * @param options Amplitude event options.
   */
  rewardsPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new RewardsPageViewed(), options);
  }

  /**
   * Search Completed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Completed)
   *
   * This event is triggered when a user completes a search on the platform. It provides valuable data on user search behavior and can be used to analyze search trends, popular search terms, and search effectiveness
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  searchCompleted(
    properties: SearchCompletedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchCompleted(properties), options);
  }

  /**
   * Search Filtered
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Filtered)
   *
   * This event tracks when a user applies a filter to their search query. It provides insights into the specific filters users are using to refine their search results
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. classification applied)
   * @param options Amplitude event options.
   */
  searchFiltered(
    properties?: SearchFilteredProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchFiltered(properties), options);
  }

  /**
   * Search Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Page%20Viewed)
   *
   * **當用戶未做搜尋，直接進入搜尋主頁時，https://www.kadokado.com.tw/search?keyword=**
   *
   * @param options Amplitude event options.
   */
  searchPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SearchPageViewed(), options);
  }

  /**
   * Search Result Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Search%20Result%20Clicked)
   *
   * This event tracks when a user clicks on a search result after performing a search
   *
   * Owner: James Lindsay
   *
   * @param properties The event's properties (e.g. genres)
   * @param options Amplitude event options.
   */
  searchResultClicked(
    properties?: SearchResultClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SearchResultClicked(properties), options);
  }

  /**
   * Signup Account Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Account%20Page%20Viewed)
   *
   * Event has no description in tracking plan.
   *
   * @param options Amplitude event options.
   */
  signupAccountPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupAccountPageViewed(), options);
  }

  /**
   * Signup Choice Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Choice%20Page%20Viewed)
   *
   * 用戶進入瀏覽註冊方案選擇頁時
   *
   * @param options Amplitude event options.
   */
  signupChoicePageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupChoicePageViewed(), options);
  }

  /**
   * Signup Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Clicked)
   *
   * 點選"立即註冊"Button
   *
   * @param options Amplitude event options.
   */
  signupClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignupClicked(), options);
  }

  /**
   * Signup Email Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Email%20Clicked)
   *
   * 用戶在註冊方案選擇頁，點擊button"使用Email註冊"
   *
   * @param options Amplitude event options.
   */
  signupEmailClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignupEmailClicked(), options);
  }

  /**
   * Signup Finished Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Finished%20Page%20Viewed)
   *
   * 瀏覽註冊完成頁
   *
   * @param options Amplitude event options.
   */
  signupFinishedPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupFinishedPageViewed(), options);
  }

  /**
   * Signup Fnished Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Fnished%20Page%20Viewed)
   *
   * **瀏覽推銷註冊-輸入密碼頁**
   *
   * @param options Amplitude event options.
   */
  signupFnishedPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupFnishedPageViewed(), options);
  }

  /**
   * Signup Google Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Google%20Clicked)
   *
   * 用戶在註冊方案選擇頁，點擊button"使用Google註冊"
   *
   * @param options Amplitude event options.
   */
  signupGoogleClicked(
    options?: EventOptions,
  ) {
    return this.track(new SignupGoogleClicked(), options);
  }

  /**
   * Signup Password Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Signup%20Password%20Page%20Viewed)
   *
   * 瀏覽推銷註冊-輸入密碼頁
   *
   * @param options Amplitude event options.
   */
  signupPasswordPageViewed(
    options?: EventOptions,
  ) {
    return this.track(new SignupPasswordPageViewed(), options);
  }

  /**
   * Smartbanner Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Smartbanner%20Clicked)
   *
   * 點選Web網頁中的smart banner"現在下載"按鈕
   *
   * @param options Amplitude event options.
   */
  smartbannerClicked(
    options?: EventOptions,
  ) {
    return this.track(new SmartbannerClicked(), options);
  }

  /**
   * Subscription Dialog Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Dialog%20Viewed)
   *
   * **跳出「訂閱限定提示」Dialog**
   *
   * @param options Amplitude event options.
   */
  subscriptionDialogViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionDialogViewed(), options);
  }

  /**
   * Subscription FAQ Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20FAQ%20Clicked)
   *
   * **點擊「FAQ」Button**
   *
   * @param options Amplitude event options.
   */
  subscriptionFaqClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionFaqClicked(), options);
  }

  /**
   * Subscription Novel Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Novel%20Clicked)
   *
   * **在訂閱方案頁上點擊「訂閱作品系列」**
   *
   * @param properties The event's properties (e.g. novels)
   * @param options Amplitude event options.
   */
  subscriptionNovelClicked(
    properties?: SubscriptionNovelClickedProperties,
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionNovelClicked(properties), options);
  }

  /**
   * Subscription Plan Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Plan%20Viewed)
   *
   * **瀏覽訂閱方案頁**
   *
   * @param options Amplitude event options.
   */
  subscriptionPlanViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionPlanViewed(), options);
  }

  /**
   * Subscription State Plan Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20State%20Plan%20Clicked)
   *
   * **點擊「查看方案」Button**
   *
   * @param options Amplitude event options.
   */
  subscriptionStatePlanClicked(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionStatePlanClicked(), options);
  }

  /**
   * Subscription State Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20State%20Viewed)
   *
   * **瀏覽「訂閱管理」頁**
   *
   * @param options Amplitude event options.
   */
  subscriptionStateViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionStateViewed(), options);
  }

  /**
   * Subscription Success Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Subscription%20Success%20Viewed)
   *
   * **跳出「訂閱成功」Dialog**
   *
   * @param options Amplitude event options.
   */
  subscriptionSuccessViewed(
    options?: EventOptions,
  ) {
    return this.track(new SubscriptionSuccessViewed(), options);
  }

  /**
   * Title Published
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Title%20Published)
   *
   * **作品上架**
   *
   * @param properties The event's properties (e.g. latest title published)
   * @param options Amplitude event options.
   */
  titlePublished(
    properties: TitlePublishedProperties,
    options?: EventOptions,
  ) {
    return this.track(new TitlePublished(properties), options);
  }

  /**
   * User Page Share Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/User%20Page%20Share%20Clicked)
   *
   * **當用戶在作者頁面點擊分享按紐時**
   *
   * @param options Amplitude event options.
   */
  userPageShareClicked(
    options?: EventOptions,
  ) {
    return this.track(new UserPageShareClicked(), options);
  }

  /**
   * User Page Viewed
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/User%20Page%20Viewed)
   *
   * **當用戶瀏覽作者頁面時**
   *
   * @param properties The event's properties (e.g. user id)
   * @param options Amplitude event options.
   */
  userPageViewed(
    properties: UserPageViewedProperties,
    options?: EventOptions,
  ) {
    return this.track(new UserPageViewed(properties), options);
  }

  /**
   * Verified Badge Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Verified%20Badge%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 認證作家申請**
   *
   * @param options Amplitude event options.
   */
  verifiedBadgeButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new VerifiedBadgeButtonClicked(), options);
  }

  /**
   * Visitor Purchase Points Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Visitor%20Purchase%20Points%20Clicked)
   *
   * 章節付費牆後跳出登入引導Dialog，點選"訪客模式"button
   *
   * @param options Amplitude event options.
   */
  visitorPurchasePointsClicked(
    options?: EventOptions,
  ) {
    return this.track(new VisitorPurchasePointsClicked(), options);
  }

  /**
   * Vistor To Homepage Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Vistor%20To%20Homepage%20Clicked)
   *
   * **在瀏覽推銷註冊頁中點擊"先用訪客模式"Button**
   *
   * @param options Amplitude event options.
   */
  vistorToHomepageClicked(
    options?: EventOptions,
  ) {
    return this.track(new VistorToHomepageClicked(), options);
  }

  /**
   * Withdrawal Record Button Clicked
   *
   * [View in Tracking Plan](https://data.amplitude.com/kadokado/KadoKado_Prod/events/main/latest/Withdrawal%20Record%20Button%20Clicked)
   *
   * **使用者點擊創作者中心的按鈕元件 - 收益請款 - 請款紀錄**
   *
   * @param options Amplitude event options.
   */
  withdrawalRecordButtonClicked(
    options?: EventOptions,
  ) {
    return this.track(new WithdrawalRecordButtonClicked(), options);
  }
}

export const ampli = new Ampli();

// BASE TYPES
type BrowserOptions = amplitude.Types.BrowserOptions;

export type BrowserClient = amplitude.Types.BrowserClient;
export type BaseEvent = amplitude.Types.BaseEvent;
export type IdentifyEvent = amplitude.Types.IdentifyEvent;
export type GroupEvent = amplitude.Types.GroupIdentifyEvent;
export type Event = amplitude.Types.Event;
export type EventOptions = amplitude.Types.EventOptions;
export type Result = amplitude.Types.Result;
